@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.Landing {
  &Wrap {
    box-sizing: border-box;
    display: flex;
    flex-flow: nowrap column;
    grid-gap: var(--LandingPage-gap);
    margin: 0 auto;
    max-width: 100%;
    padding: var(--LandingPage-gap) calc(var(--LandingPage-gap) / 2);
    width: 1000px;
  }

  &Block {
    align-items: center;
    display: grid;
    grid-auto-columns: 1fr;

    &-link_viewAll {
      margin-top: $xs;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.75;
      }
    }

    &_align {
      &\=right {
        text-align: right;
      }

      &\=center {
        text-align: center;
      }

      &\=left {
        text-align: left;
      }
    }

    a {
      &:not(:hover) {
        text-decoration: none !important;
      }
    }

    hr {
      border-color: var(--LandingPage-edge);
    }

    ul {
      list-style: none;
      padding: 0 !important;
    }

    :global(.heading) {
      display: inline-flex;
    }

    &-Three {
      align-items: baseline;
      display: flex;
      grid-gap: var(--LandingPage-gap);
      justify-content: center;

      > * {
        flex: 0 33.33%;
      }
    }

    &-Docs,
    &-Links {
      align-items: baseline;
      display: flex;
      flex-flow: wrap row;
      grid-gap: var(--LandingPage-gap);

      a {
        color: var(--LandingPage-link-color);

        &:hover,
        &:focus {
          color: var(--LandingPage-link-color);
        }
      }

      li {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      h3 {
        border-bottom: 1px solid var(--LandingPage-edge);
        color: var(--LandingPage-heading-color, inherit) !important;
        padding-bottom: 0.2em;
      }

      > * {
        flex: 1 calc(33% - var(--LandingPage-gap));
        max-width: 33%;
      }
    }

    &-TextMedia {
      display: flex;
      grid-gap: var(--LandingPage-gap);

      &_reverse {
        > :last-child {
          order: -1;
        }
      }

      > * {
        flex: 1 calc(50% - var(--LandingPage-gap));
        max-width: 50%;
      }

      &:last-child img:only-child {
        margin-bottom: calc(-1 * var(--LandingPage-gap));
      }

      &-media {
        flex: 1 0 50%;
        max-width: 50%;
        text-align: initial;

        &:global(.markdown-body) {
          pre {
            margin-bottom: 0 !important;
          }
        }

        &:is(img) {
          max-height: 42vh;
          max-width: 50%;
          object-fit: contain;
        }

        &:only-child {
          margin: 0 auto;
          max-height: unset;
          max-width: 100%;
        }

        &:not(img) {
          max-width: 60% !important;
          width: 100%;
        }
      }
    }

    @media (min-width: 768px) {
      &-Links {
        justify-content: center;

        > * {
          margin: 0;
        }
      }

      &-Docs {
        // Constrain incomplete row items width
        // so that it accounts for the --gap!
        $offset: '32.8% + (var(--LandingPage-gap) / 2)';

        > :last-child:nth-child(3n-1) {
          margin-right: calc(#{$offset});
        }

        > :last-child:nth-child(3n-2) {
          margin-right: calc((#{$offset}) * 2);
        }
      }
    }

    @media (max-width: 768px) {
      &Wrap {
        --gap: 1.5em;

        box-sizing: border-box;
        padding: var(--LandingPage-gap);
        width: 100%;
      }

      & {
        flex-flow: nowrap column;
      }

      &-Docs,
      &-Links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        > * {
          max-width: unset;
        }
      }

      &-TextMedia {
        > * {
          max-width: unset;
        }

        &-media {
          max-width: 100% !important;

          &:not(img) {
            max-width: unset !important;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 480px) {
      &-Docs,
      &-Links {
        grid-template-columns: 1fr;
      }
    }
  }
}

:global(.rm-LandingPage) {
  --LandingPage-heading-color: var(--color-primary);
  --LandingPage-edge: var(--color-border-default, var(--edge));
  --LandingPage-link-color: var(--color-text-muted);
  --LandingPage-gap: var(--gap, 3em);

  :global(.ThemeContext_dark) & {
    @include dark-mode {
      --LandingPage-heading-color: var(--color-text-default);
    }
  }

  :global(.ThemeContext_light.ThemeContext_line) & {
    --LandingPage-heading-color: var(--color-text-default);
  }
}

:global(.rm-GroupLanding) {
  --GroupLanding-project-size: 216px; // about enough for 6 at our default max container size

  display: flex;
  flex-wrap: wrap;
  gap: var(--md);
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  padding: var(--lg) var(--md);
  padding-bottom: var(--lg);
  padding-top: var(--lg);
}

:global(.rm-GroupLanding-project) {
  height: var(--GroupLanding-project-size);
  width: var(--GroupLanding-project-size);
}

:global(.rm-GroupLanding-link) {
  align-items: center;
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius-lg);
  box-sizing: border-box;
  color: var(--color-text-default);
  display: flex;
  gap: var(--md);
  height: 100%;
  justify-content: center;
  padding: var(--md);
  transition: box-shadow var(--transition-slow) var(--transition-timing);
  width: 100%;
}

:global(.rm-GroupLanding-link):hover {
  border-color: var(--color-input-border-hover);
  color: var(--color-text-default);
}

:global(.rm-GroupLanding-link:active),
:global(.rm-GroupLanding-link:focus) {
  border-color: var(--color-input-border-active);
}

:global(.rm-GroupLanding-link:focus) {
  box-shadow: 0 0 0 3px var(--color-input-border-focus);
  outline: 0;
}

:global(.rm-GroupLanding-link-img) {
  flex: 1 1;
  max-width: 100%;
  object-fit: contain;
}
